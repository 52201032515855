<template>
	<div id="app">
		<!-- router views -->
		<transition appear name="fade" mode="out-in">
			<router-view :key="name" />
		</transition>
	</div>
</template>

<script>
import store from '@/js/store';

const Main = () => import('@/views/Main.vue');
const Cms = () => import('@/views/Cms.vue');

export default {
	data: () => ({
		name: '',
	}),
	components: {},
	computed: {
		pages() {
			let pages = this.$store.pages;
			if (pages.length > 0) {
				return pages;
			}
		},
	},
	async created() {
		console.clear();
		// console.log('app.vue');

		await this.$store.getPages();
		this.createRoutes();
	},
	watch: {
		$route: async function (to, from) {
			this.name = to.name == '' || to.name == null ? 'home' : to.name;
			console.log('route change', from.name, '=>', this.name);
			await this.$store.getQuarks(this.name);
		},
	},
	methods: {
		createRoutes() {
			// get current routes to check if route already exist before adding
			let routes = this.$router.getRoutes();

			// always add '/' route
			let element = {
				path: '/',
				name: '',
				component: Main,
				props: { route: '/' },
			};
			if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

			// add routes from cms
			let response = this.$store.pages;
			for (let i = 0; i < response.length; i++) {
				let path = response[i].path;
				let component;

				element = {
					path: '/' + path,
					name: path,
					component: Main,
					props: { route: path },
				};

				if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);
			}

			// always add '/cms' route
			element = {
				path: '/cms',
				name: 'cms',
				component: Cms,
				props: { route: '/cms' },
			};
			if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

			// uncomment the following lines to generate info for sitemap.txt file
			// routes = this.$router.getRoutes();
			// let url = 'https://dierenartsterkeurs.nl';
			// routes.forEach((element) => {
			// 	console.log(url + element.path);
			// });
		},
	},
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

@font-face {
	font-family: BalooTabba2Bold;
	src: url('assets/font/BalooTamma2-Bold.ttf');
}

#app {
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: left;
	color: #544547;
}

h1 {
	font-family: 'BalooTabba2Bold', sans-serif;
	font-size: 20px;
	text-transform: uppercase;
}

h2 {
	font-family: 'BalooTabba2Bold';
	font-size: 18px;
}

h3 {
	font-size: 24px;
	font-weight: 400;
	font-style: normal;
	color: #f9b233;
}

p {
	font-size: 16px;
	font-family: Roboto;
}

a {
	color: inherit;
}

.img-fluid {
	width: 100%;
}

.nav {
	font-family: 'BalooTabba2Bold', sans-serif;
	font-size: 20px;
	color: #712281;
	text-transform: uppercase;
}

.contact {
	font-family: 'BalooTabba2Bold', sans-serif;
	font-size: 20px;
	color: #eba900;
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.5s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
